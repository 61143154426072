body {
  margin: 0;
  padding: 0;
  background-color: #212121;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.top-center {
  top: 70px !important;
}

/* Need to modify to black as the default white isn't good enough contrast */
.redux-toastr :is(.toastr.rrt-warning, .toastr.rrt-error, .toastr.rrt-info, .toastr.rrt-success) {
  color: #000000;
}

.redux-toastr .toastr .rrt-left-container .toastr-icon {
  fill: #000000;
}

@keyframes rotate {
  to {
    transform: rotateZ(360deg);
  }
}
